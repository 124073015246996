import useOwner from "@/hooks/useOwner";
import styled from "styled-components";
import Button from "../universal/Button/Button";
import { colors } from "@/lib/styles";
import Popper from "../universal/Popper";
import { useMutation } from "react-query";
import { Status as StatusType, STATUS, statusColors } from "@/models/status";
import usePreview from "@/hooks/usePreview";
import { Folder } from "@/models/folder";
import updateFolder from "@/db/folder/updateFolder";
import updateProgram from "@/db/program/updateProgram";
import { useRouter } from "next/router";
import useProgram from "@/hooks/useProgram";

const Statuses = styled.div`
  align-self: stretch;
`;

const Option = styled.div<{
  color: string;
  hoverColor: string;
  active: boolean;
}>`
  align-self: stretch;
  width: 100%;
  background-color: ${(props) => props.color};
  border-radius: 10px;
  padding: 8px 12px;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: ${(props) => (props.active ? 500 : 400)};
  min-width: 100px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  border: 1px solid ${(props) => (props.active ? props.color : colors.white)};
  &:first-of-type {
    margin-top: 0;
  }
  &:hover {
    background-color: ${(props) => props.hoverColor};
    cursor: pointer;
    font-weight: 500;
  }
`;

type Props = {
  folder?: Folder;
  style?: object;
};

const Status: React.FC<Props> = ({ folder, style }) => {
  const owner = useOwner();
  const program = useProgram();
  const preview = usePreview();
  const router = useRouter();

  const updateFolderMutation = useMutation(updateFolder, {
    onSuccess: () => {
      router.reload();
    },
  });
  const updateProgramMutation = useMutation(updateProgram);

  const status = folder?.status || program?.status || null;
  if (!status) return null;

  const color = statusColors[status];

  const update = (status: StatusType) => () => {
    if (folder) {
      updateFolderMutation.mutate({
        owner,
        folderId: folder.id,
        folderUpdate: { status },
      });
    } else if (program) {
      updateProgramMutation.mutate({
        programId: program.id,
        programUpdate: { status },
      });
    }
  };

  return (
    <Popper
      style={style}
      placement="bottom"
      component={(props) => (
        <Button
          color={colors.nearBlack}
          backgroundColor={color[0]}
          hoverBackgroundColor={color[1]}
          style={{
            margin: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingRight: 12,
            paddingLeft: 12,
          }}
          {...props}
        >
          {status[0].toUpperCase() + status.slice(1).toLowerCase()}
        </Button>
      )}
    >
      <Statuses>
        {STATUS.filter((v) => v.value !== "ARCHIVED").map((option) => (
          <Option
            color={statusColors[option.value][0]}
            hoverColor={statusColors[option.value][1]}
            key={`status-${option.value}`}
            onClick={update(option.value)}
            active={status === option.value}
          >
            {option.title}
          </Option>
        ))}
      </Statuses>
    </Popper>
  );
};

export default Status;
