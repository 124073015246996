import styled from "styled-components";
import { colors } from "@/lib/styles";
import Button from "./Button/Button";
import { toast } from "react-hot-toast";
import LinkIcon from "../icons/LinkIcon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
`;

const Overlay = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid ${colors.lightGray};
  padding: 10px 0px;
  pointer-events: none;
  opacity: 0;
  background-color: ${colors.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 43px;
  left: -1px;
  z-index: 80;
  overflow: visible;
  transform: scale(0.8);
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 20px 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
  }
`;

const Permissions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 33px 10px 20px;
  height: 100%;
  border-left: 1px solid ${colors.lightGray};
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  margin-right: -10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: ${colors.darkGray};
  font-size: 16px;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
    border-bottom-left-radius: 0px;
  }
  &:hover ${Overlay} {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
`;

const Permission = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.white};
  width: 200px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  font-size: 16px;
  color: ${colors.nearBlack};
  padding: 10px 15px 10px 20px;
  &:hover {
    background-color: ${colors.nearWhite};
    cursor: pointer;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  title?: string;
  link: string;
  disabled?: boolean;
  disabledMessage?: string;
  style?: object;
};

export const CopyLink: React.FC<Props> = ({ link, title, disabled, style }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
  };

  return (
    <>
      <Wrapper style={style}>
        <Button
          backgroundColor={colors.nearWhite}
          color={!disabled ? colors.nearBlack : colors.gray}
          hoverColor={colors.nearBlack}
          borderColor={colors.lightGray}
          hoverBorderColor={colors.gray}
          style={{ margin: 0, padding: "10px 15px", height: 45 }}
          onClick={copyToClipboard}
          disabled={disabled}
        >
          <LinkIcon
            color={!disabled ? colors.nearBlack : colors.gray}
            style={{ marginRight: 10 }}
            size={20}
          />
          {title || "Copy link"}
        </Button>
      </Wrapper>
    </>
  );
};
export default CopyLink;
