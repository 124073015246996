import styled from "styled-components";
import LoadingAnimation from "@/components/universal/Loading";

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 80px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
`;

type Props = {};

export const LoadingScreen: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <LoadingAnimation size={40} />
    </Wrapper>
  );
};

export default LoadingScreen;
