import TabBar from "@/components/universal/TabBar";
import { ownerPath } from "@/lib/path";
import styled from "styled-components";
import useOwner from "@/hooks/useOwner";
import CopyLink from "../universal/CopyLink";
import { ownerDomain } from "@/lib/owner";
import useProgram from "@/hooks/useProgram";
import useAdmin from "@/hooks/useAdmin";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import useGroup from "@/hooks/useGroup";
import Status from "../universal/Status";

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
`;

export const ProgramTabBar: React.FC = (props) => {
  const owner = useOwner();
  const program = useProgram();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const group = useGroup();

  return (
    <>
      <TabBar
        title={program?.title}
        image={program?.image}
        action={
          admin ? (
            <Action>
              <Status style={{ marginRight: "auto" }} />
              <CopyLink link={`${ownerDomain(owner)}/program/${program.id}`} />
            </Action>
          ) : groupAdmin ? (
            <Action>
              <CopyLink
                style={{ marginLeft: "auto" }}
                link={`${ownerDomain(owner)}/program/${program.id}?group=${
                  group?.id
                }`}
              />
            </Action>
          ) : null
        }
        tabs={
          admin
            ? [
                {
                  title: "Content",
                  name: "content",
                  path: ownerPath(
                    owner,
                    [...program?.slugPaths, program?.slug].join("/")
                  ),
                },
                {
                  title: "About",
                  name: "about",
                  path: ownerPath(owner, `program/${program.id}/about`),
                },
                {
                  title: "Sessions",
                  name: "sessions",
                  path: ownerPath(owner, `program/${program.id}/sessions`),
                },
                {
                  title: "Surveys",
                  name: "surveys",
                  path: ownerPath(owner, `program/${program.id}/surveys`),
                },
                {
                  title: "Settings",
                  name: "settings",
                  path: ownerPath(owner, `program/${program.id}/settings`),
                },
              ]
            : groupAdmin
            ? [
                {
                  title: "Content",
                  name: "content",
                  path: ownerPath(
                    owner,
                    [...program.slugPaths, program.slug].join("/")
                  ),
                },
                {
                  title: "Sessions",
                  name: "sessions",
                  path: ownerPath(owner, `program/${program.id}/sessions`),
                },
                {
                  title: "Surveys",
                  name: "surveys",
                  path: ownerPath(owner, `program/${program.id}/surveys`),
                },
              ]
            : []
        }
      />
    </>
  );
};

export default ProgramTabBar;
