import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import EmptyIcon from "./EmptyIcon";

const Wrapper = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 101;
  padding: 0 20px;
  top: 60px;
  position: sticky;
  background-color: ${colors.white};
`;

const Title = styled.h1`
  font-size: 21px;
  color: ${colors.nearBlack};
  font-weight: 600;
  text-align: left;
  white-space: wrap;
  margin-right: 20px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  padding: 20px 0 0px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

const ImageWrapper = styled.div<{ round?: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: ${(props) => (props.round ? "50%" : "0px")};
  overflow: hidden;
  margin-right: 15px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
`;

const Tab = styled.a<{ active: boolean }>`
  display: inline-block;
  text-decoration: none;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 36px;
  font-size: 16px;
  font-weight: ${(props) => (props.active ? 500 : 400)};
  color: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
    color: ${colors.nearBlack};
  }
`;

const Active = styled.div<{ active: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${colors.nearBlack};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  opacity: ${(props) => (props.active ? 1 : 0)};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const BottomBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  height: 1px;
  background-color: ${colors.lightGray};
`;

type Props = {
  image?: string;
  title?: string;
  action?: React.ReactElement;
  tabs: {
    name: string;
    title: string;
    path: string;
    icon?: React.ReactElement;
  }[];
  round?: boolean;
};

const TabBar: React.FC<Props> = ({ image, title, tabs, action, round }) => {
  const router = useRouter();
  const url = router.asPath;

  return (
    <Wrapper>
      <BottomBar />
      {(title || image) && (
        <Header>
          {image && (
            <ImageWrapper round={round}>
              <Image
                src={image}
                objectFit="contain"
                layout="fixed"
                width={50}
                height={50}
                priority
              />
            </ImageWrapper>
          )}
          {image === undefined && (
            <EmptyIcon size={50} style={{ marginRight: 15 }} title={title} />
          )}
          {title && <Title>{title}</Title>}
          {action}
        </Header>
      )}
      <Tabs>
        {tabs.map((tab) => {
          return (
            <Link
              key={`tab-${tab.name}`}
              href={tab.path}
              shallow={true}
              passHref={true}
            >
              <Tab
                data-cy={`tab-${tab.name}`}
                active={router.asPath.split("?")[0] === tab.path}
              >
                {!!tab.icon && tab.icon}
                {tab.title}
                <Active active={router.asPath.split("?")[0] === tab.path} />
              </Tab>
            </Link>
          );
        })}
      </Tabs>
    </Wrapper>
  );
};

export default TabBar;
