import { colors } from "@/lib/styles";
import { rgba } from "polished";

export type Status = "PRIVATE" | "TEASER" | "PUBLIC" | "ARCHIVED";

export const STATUS: { title: string; value: Status }[] = [
  {
    title: "Private",
    value: "PRIVATE",
  },
  {
    title: "Teaser",
    value: "TEASER",
  },
  {
    title: "Public",
    value: "PUBLIC",
  },
  {
    title: "Archived",
    value: "ARCHIVED",
  },
];

// for each status, the background color & hover background color of the status indicator
export const statusColors: { [key in Status]: [string, string] } = {
  PRIVATE: [colors.nearWhite, colors.lightGray],
  TEASER: [rgba(colors.yellow, 0.1), rgba(colors.yellow, 0.2)],
  PUBLIC: [rgba(colors.green, 0.1), rgba(colors.green, 0.2)],
  ARCHIVED: [colors.nearWhite, colors.gray],
};
