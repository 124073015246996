import Button from "@/components/universal/Button/Button";
import styled from "styled-components";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "@/redux/index";
import { authSelectors, GoogleAuth, LogOut } from "@/redux/auth";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import { userSelectors } from "@/redux/user";
import { useMutation, useQuery } from "react-query";
import startSession from "@/db/session/startSession";
import { useRouter } from "next/router";
import { ownerPath } from "@/lib/path";
import Image from "next/image";
import useOwner from "@/hooks/useOwner";
import useGroup from "@/hooks/useGroup";
import useProgram from "@/hooks/useProgram";
import Loading from "../universal/Loading";
import { toast } from "react-hot-toast";
import { rgba } from "polished";
import useOwnerColor from "@/hooks/useOwnerColor";
import PlayIcon from "../icons/PlayIcon";
import LoadingScreen from "../App/LoadingScreen";
import useAdmin from "@/hooks/useAdmin";
import ProgramTabBar from "../Program/ProgramTabBar";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import usePreview from "@/hooks/usePreview";
import BuildIcon from "../icons/BuildIcon";
import { useCallback } from "react";
import useAccess from "@/hooks/useAccess";
import LockIcon from "../icons/LockIcon";
import { Session } from "@/models/session";
import getSession from "@/db/session/getSession";
import { Tag } from "@/models/tag";
import getTags from "@/db/tag/getTags";
import { find } from "lodash";
import TagComponent from "../universal/Tags/Tag";
import moment from "moment";
import updateUser from "@/db/user/updateUser";
import getEmailGroup from "@/db/group/getEmailGroup";
import { firestore } from "@/firebase";
import { Group } from "@/models/group";
import firebase from "firebase";
import * as Sentry from "@sentry/nextjs";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto 0;
  position: relative;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border: 1px solid ${colors.lightGray};
  background-color: ${colors.white};
  border-radius: 10px;
  max-width: 600px;
  margin-bottom: 20px;
`;

const CardContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  justify-content: center;
  padding: 20px 20px 0;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 15px;
  color: ${colors.nearBlack};
  text-align: left;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

const Description = styled.p`
  font-size: 21px;
  font-weight: 400;
  color: ${colors.darkGray};
  margin: 0 0 15px;
  text-align: left;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10px 10px;
  width: 100%;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  object-fit: cover;
`;

const CoverImage = styled.div<{ color: string; empty?: boolean }>`
  width: 100%;
  height: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${colors.lightGray};
  overflow: hidden;
  position: relative;
  background-color: ${(props) =>
    props.empty ? rgba(props.color, 0.1) : colors.nearWhite};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: 150px;
  }
`;

type OwnProps = {};
type Props = OwnProps & ConnectedProps<typeof connector>;

export const Start: React.FC<Props> = (props) => {
  const { loggedIn, userName, userEmail, uid, isAuthenticating } = props;

  const group = useGroup();
  const program = useProgram();
  const owner = useOwner();
  const router = useRouter();
  const ownerColor = useOwnerColor();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const preview = usePreview();
  const adminView = (admin || groupAdmin) && !preview;

  const tagsQuery = useQuery<Tag[]>(["tags", owner?.id], getTags(owner));

  const sessionQuery = useQuery<Session>(
    ["session", program?.id, uid],
    uid && program?.id
      ? getSession({ userId: uid, programId: program?.id })
      : null,
    {
      refetchOnWindowFocus: false,
    }
  );
  const session = sessionQuery.data;

  const buttonStyle = {
    margin: 10,
    flex: 1,
  };
  const buttonDesktopStyle = {
    fontSize: 21,
  };
  const buttonMobileStyle = {
    fontSize: 16,
  };

  const hasKey = router.query?.key === program?.id;

  const startSessionMutation = useMutation(startSession, {
    onSuccess: (session) => {
      const sessionComplete = !!session.completedTime;
      const sessionId = session.id;
      const link = sessionComplete
        ? ownerPath(owner, `program/${program.id}/complete`, {
            session: sessionId,
          })
        : ownerPath(owner, `program/${program.id}`, { session: sessionId });
      router.push(link, undefined, { shallow: false });
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.custom("Could not start session - try refreshing the page");
    },
  });

  const access = useAccess(program);

  const start = useCallback(
    (userId: string) => {
      startSessionMutation.mutate({
        programId: program.id,
        orgId: owner.id,
        userId,
      });
    },
    [program?.id, owner?.id, group?.id]
  );

  if (!program) return <LoadingScreen />;

  const loading = isAuthenticating || startSessionMutation.isLoading;

  console.log("ACCESS", access);

  return (
    <>
      {adminView && <ProgramTabBar />}
      <Wrapper>
        {!!program.backgroundImage && (
          <Background>
            <Image
              src={program.backgroundImage}
              layout="fill"
              objectFit="cover"
            />
          </Background>
        )}
        <Card>
          <CoverImage color={ownerColor} empty={!program.coverImage}>
            {program.coverImage && (
              <Image layout="fill" objectFit="cover" src={program.coverImage} />
            )}
          </CoverImage>
          <CardContents>
            <Title>{program.title}</Title>
            {!!program.description && (
              <Description>{program.description}</Description>
            )}
            {!!program.tags && tagsQuery.data && (
              <Tags>
                {program.tags?.map((tag, i) => (
                  <TagComponent
                    key={`tag-${i}`}
                    tag={find(tagsQuery.data, { id: tag })}
                  />
                ))}
              </Tags>
            )}
          </CardContents>
          {(admin || groupAdmin) && access && (
            <CardActions>
              <Button
                style={buttonStyle}
                desktopStyle={buttonDesktopStyle}
                mobileStyle={buttonMobileStyle}
                hoverBorderColor={colors.gray}
                backgroundColor={colors.nearWhite}
                borderColor={colors.lightGray}
                color={colors.nearBlack}
                href={ownerPath(owner, `program/${program.id}/preview`)}
              >
                <PlayIcon
                  color={colors.nearBlack}
                  size={21}
                  style={{
                    marginRight: 10,
                    transform: "translateY(2px)",
                  }}
                />
                Preview
              </Button>
              {admin && (
                <Button
                  style={buttonStyle}
                  desktopStyle={buttonDesktopStyle}
                  mobileStyle={buttonMobileStyle}
                  backgroundColor={ownerColor}
                  hoverBackgroundColor={rgba(ownerColor, 0.9)}
                  color={colors.white}
                  href={ownerPath(owner, `program/${program.id}/build`, {
                    i: 0,
                  })}
                >
                  <BuildIcon
                    style={{
                      marginRight: 10,
                      transform: "translateY(2px)",
                    }}
                    size={24}
                    color={colors.white}
                  />
                  Edit
                </Button>
              )}
              {groupAdmin && !admin && (
                <Button
                  style={buttonStyle}
                  desktopStyle={buttonDesktopStyle}
                  mobileStyle={buttonMobileStyle}
                  hoverBackgroundColor={rgba(ownerColor, 0.9)}
                  backgroundColor={ownerColor}
                  color={colors.white}
                  onClick={() => start(uid)}
                  disabled={loading}
                >
                  <PlayIcon
                    color={colors.white}
                    fill={colors.white}
                    size={21}
                    style={{
                      marginRight: 10,
                      transform: "translateY(2px)",
                    }}
                  />
                  {!loading && !session && "Start"}
                  {!loading &&
                    !!(session && !session.completedTime) &&
                    "Resume"}
                  {!loading &&
                    !!session &&
                    !!session?.completedTime &&
                    "Take again"}
                  {loading && (
                    <>
                      Loading
                      <Loading size={20} light style={{ marginLeft: 20 }} />
                    </>
                  )}
                </Button>
              )}
            </CardActions>
          )}
          {!(admin || groupAdmin) && access && (
            <CardActions>
              {["TEASER", "PRIVATE"].includes(program.status) && !hasKey && (
                <Button
                  style={buttonStyle}
                  desktopStyle={buttonDesktopStyle}
                  mobileStyle={buttonMobileStyle}
                  backgroundColor={colors.nearWhite}
                  borderColor={colors.lightGray}
                  color={colors.gray}
                  disabled
                >
                  Coming soon
                </Button>
              )}

              {(program.status === "PUBLIC" || hasKey) && (
                <>
                  {!!session &&
                    !!session.completedTime &&
                    !!program.certificate && (
                      <Button
                        style={buttonStyle}
                        desktopStyle={buttonDesktopStyle}
                        mobileStyle={buttonMobileStyle}
                        hoverBorderColor={colors.gray}
                        backgroundColor={colors.nearWhite}
                        borderColor={colors.lightGray}
                        color={colors.nearBlack}
                        href={ownerPath(
                          owner,
                          `program/${program.id}/complete`,
                          {
                            session: session.id,
                          }
                        )}
                      >
                        {moment.unix(session.completedTime).format("YYYY")}{" "}
                        Certificate
                      </Button>
                    )}
                  <Button
                    style={buttonStyle}
                    desktopStyle={buttonDesktopStyle}
                    mobileStyle={buttonMobileStyle}
                    hoverBackgroundColor={rgba(ownerColor, 0.9)}
                    backgroundColor={ownerColor}
                    color={colors.white}
                    onClick={
                      loggedIn
                        ? () => start(uid)
                        : () =>
                            props.GoogleAuth(
                              async (workspace, userId, userEmail) => {
                                // match groups to user
                                const explicitGroupId = router.query
                                  .group as string;
                                if (owner) {
                                  let group: Group;
                                  if (explicitGroupId) {
                                    // get group
                                    const groupDoc = await firestore
                                      .collection("orgs")
                                      .doc(owner.id)
                                      .collection("groups")
                                      .doc(explicitGroupId)
                                      .get();
                                    group = groupDoc.data() as Group;
                                  } else {
                                    const emailGroup = await getEmailGroup(
                                      owner.id,
                                      userEmail?.split("@")?.[1]
                                    )();
                                    if (emailGroup) group = emailGroup;
                                  }

                                  if (group) {
                                    if (group.parent) {
                                      await updateUser({
                                        userId,
                                        userUpdate: {
                                          memberships:
                                            firebase.firestore.FieldValue.arrayUnion(
                                              `${owner.id}/${group.parent}`
                                            ) as any,
                                        },
                                      });
                                    }
                                    await updateUser({
                                      userId,
                                      userUpdate: {
                                        memberships:
                                          firebase.firestore.FieldValue.arrayUnion(
                                            group.parent
                                              ? `${owner.id}/${group.parent}/${group.id}`
                                              : `${owner.id}/${group.id}`
                                          ) as any,
                                      },
                                    });
                                  }
                                }
                                start(userId);
                              }
                            )
                    }
                    disabled={loading}
                  >
                    <PlayIcon
                      color={colors.white}
                      fill={colors.white}
                      size={21}
                      style={{
                        marginRight: 10,
                        transform: "translateY(2px)",
                      }}
                    />
                    {!loading && !session && "Start"}
                    {!loading &&
                      !!(session && !session.completedTime) &&
                      "Resume"}
                    {!loading &&
                      !!(session && !!session.completedTime) &&
                      (!!program.certificate
                        ? "Renew certificate"
                        : "Take again")}
                    {loading && (
                      <>
                        Loading
                        <Loading size={20} light style={{ marginLeft: 20 }} />
                      </>
                    )}
                  </Button>
                </>
              )}
            </CardActions>
          )}
          {!access && (
            <CardActions>
              <Button
                style={buttonStyle}
                desktopStyle={buttonDesktopStyle}
                mobileStyle={buttonMobileStyle}
                backgroundColor={colors.nearWhite}
                borderColor={colors.lightGray}
                color={colors.gray}
                disabled
              >
                <LockIcon
                  color={colors.gray}
                  size={21}
                  style={{ transform: "translateY(2px)", marginRight: 15 }}
                />
                Request access
              </Button>
            </CardActions>
          )}
        </Card>
      </Wrapper>
    </>
  );
};

const mapState = (state: RootState) => ({
  uid: authSelectors.uid(state),
  userName: userSelectors.userName(state),
  userEmail: userSelectors.userEmail(state),
  loggedIn: authSelectors.loggedIn(state),
  isAuthenticating: authSelectors.isAuthenticating(state),
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      GoogleAuth,
      LogOut,
    },
    dispatch
  );
const connector = connect(mapState, mapDispatch);

export default connector(Start);
